const mainNavItems = [
    // {
    //     href: "/services",
    //     // icon: CheckIcon,
    //     title: "Services",
    // },
    // {
    //     href: "/about",
    //     // icon: UserIcon,
    //     title: "About Us",
    // },
];
export default mainNavItems;
