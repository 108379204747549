import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Tabs, Tab, useTheme } from "@material-ui/core";
import LogitHelmet from "src/components/shared/LogitHelmet";
import { a11yProps, TabPanel } from "src/components/shared/LogitTabs";

import PdfContainer from "src/components/shared/PdfContainer";

import { LOGIT_LEGAL_DOCUMENTS } from "src/Constants";
const Legal = (props) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        setValue(props.tab);
    }, [props.tab]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            window.history.pushState(
                "",
                "Terms and Conditions",
                "/legal/terms"
            );
        } else if (newValue === 1) {
            window.history.pushState("", "Privacy Policy", "/legal/privacy");
        } else {
            window.history.pushState("", "Legal", "/legal");
            // window.history.replaceState("", "Legal", "/legal");
        }
    };

    const theme = useTheme();
    return (
        <>
            <LogitHelmet pageName={"Legal"}></LogitHelmet>

            <Box
                elevation={3}
                pt={5}
                sx={{
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    elevation: 2,
                    backgroundColor: theme.palette.secondary.main,
                }}
            >
                <Grid
                    container
                    backgroundColor="paper.default"
                    display="flex"
                    justifyContent="center"
                    p={10}
                >
                    <Grid item xs={12} md={7} pb={3}>
                        <Typography
                            variant="h1"
                            color={theme.palette.white.main}
                            xs={12}
                            elevation={10}
                        >
                            Legal
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography
                            variant="body1"
                            color={theme.palette.white.main}
                        ></Typography>
                    </Grid>
                </Grid>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    backgroundColor="paper.default"
                    aria-label="Service Tabs"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    style={{
                        color: "primary",
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Tab label="Terms and Conditions" {...a11yProps(0)} />
                    <Tab label="Privacy Policy" {...a11yProps(1)} />
                </Tabs>
            </Box>

            <Box
                sx={{
                    backgroundColor: "paper.default",
                    minHeight: "100%",
                    py: 3,
                    p: { md: 5, lg: 10, xl: 15 },
                }}
            >
                <TabPanel value={value} index={0}>
                    <PdfContainer
                        title="Terms and Conditions"
                        url={LOGIT_LEGAL_DOCUMENTS["terms"]}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PdfContainer
                        title="Privacy Policy"
                        url={LOGIT_LEGAL_DOCUMENTS["privacy"]}
                    />
                </TabPanel>
            </Box>
        </>
    );
};

export default Legal;
