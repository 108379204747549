import React from "react";
import ServicesSection from "src/components/services/ServicesSection";
import { Button } from "@material-ui/core";
import { ContactUs } from "src/utils/marketing";

import OpenInNew from "@material-ui/icons/OpenInNew";

const ContactUsButton = (props) => {
    return (
        <Button
            color={props.color ? props.color : "accent"}
            size="small"
            disableElevation={props.disableElevation ? true : false}
            variant="contained"
            onClick={(e) => ContactUs(e)}
            endIcon={<OpenInNew />}
        >
            {props.title ? props.title : "Contact Us"}
        </Button>
    );
};

export default ContactUsButton;
