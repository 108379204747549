import {
	Container,
	Grid,
	IconButton,
	Card,
	CardHeader,
	Divider,
} from '@material-ui/core';

import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { GetDownloadUrl } from 'src/utils/cloudStorage';
import OpenInNew from '@material-ui/icons/OpenInNew';

const PdfContainer = (props) => {
	const navigation = useLocation();

	const [currentAttachment, setCurrentAttachment] = useState(null);

	useEffect(() => {
		async function getUrl() {
			let response = await GetDownloadUrl(props.url);
			setCurrentAttachment(response);
			return;
		}
		getUrl();
		pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	}, [navigation.state, props.url]);

	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function onAttachmentFullScreen() {
		window.open(currentAttachment);
	}

	return (
		<Container maxWidth={false}>
			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					lg={12}
					md={12}
					xl={12}
					xs={12}
				>
					<Card m={3}>
						<CardHeader
							title={props.title}
							avatar={
								<div>
									<IconButton onClick={onAttachmentFullScreen}>
										<OpenInNew />
									</IconButton>
								</div>
							}
						></CardHeader>
						<Divider />
						<div
							id='spec-container'
							style={{
								justifyContent: 'center',
								overflow: 'hidden',
								zoom: '100%',
								overflowY: 'scroll',
								overflowX: 'scroll',
								height: 'auto',
								width: 'auto',
								maxHeight: 1000,
								alignItems: 'center',
								// border: "1px solid red",
							}}
						>
							{currentAttachment ? (
								<Document
									file={currentAttachment}
									onLoadSuccess={onDocumentLoadSuccess}
									width='auto'
									objectFit='cover'
									style={{
										border: '1px solid green',
										width: '100%',
									}}
								>
									{Array.from(new Array(numPages), (el, index) => (
										<Page
											key={`page_${index + 1}`}
											pageNumber={index + 1}
											debug={true}
											width={
												document
													.getElementById('spec-container')
													.getBoundingClientRect().width
											}
										/>
									))}
								</Document>
							) : null}
						</div>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
};

export default PdfContainer;
