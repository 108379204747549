import { GetGeojsonCutblocks } from "./api";
import { formatDateForHBS, formatTimbermarkForHbs } from "./helpers";
import { BCDATA_API_ENDPOINT_V2 } from "src/Config";
const URL = BCDATA_API_ENDPOINT_V2 + "/hbs";

async function MakeRequest(url) {
    let response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            let r = response.json();

            return r;
        })
        .catch((error) => {
            return false;
        });

    return response;
}
export async function GetSaleInvoicesFromHBS(sale, scaleType) {
    let timberMark = formatTimbermarkForHbs(sale.ffid);
    let from = formatDateForHBS(new Date(Date.parse(sale.closed_time)));
    let to = formatDateForHBS(new Date());
    const timberMarkInformation = await MakeRequest(
        `${URL}/status/mark/${timberMark}`
    );

    // const json = MakeRequest(
    //     `${URL}?scaleType=${scaleType}&timberMark=${timberMark}&from=${from}&to=${to}`
    // );
    const json = MakeRequest(
        `${URL}/invoices/markAndScaleType/${timberMark}/${scaleType}?from=${from}&to=${to}`
    );
    // cache me

    return json;
}

export async function GetSaleStatusFromHBS(timberMark) {
    const json = MakeRequest(`${URL}/status/mark/${timberMark}`);

    return json;
}

export async function CheckIfTimbermarkExistsInHBS(timberMark) {
    const json = await MakeRequest(`${URL}/status/mark/${timberMark}`);
    // check if appraisalInfo  and clientInfo exists
    if (json.appraisalInfo && json.clientInfo) {
        return true;
    }
    return false;
}

export async function GetAllSaleInvoicesFromHBS(timberMark, from, to = null) {
    let fromDate = formatDateForHBS(new Date(Date.parse(from)));
    if (to === null) {
        to = formatDateForHBS(new Date());
    }
    let toDate = formatDateForHBS(new Date(Date.parse(to)));

    const json = await MakeRequest(
        `${URL}/invoices/mark/${timberMark}?startDate=${from}&endDate=${to}`
    );

    return json;
}

export async function GetClientInvoicesFromHBS(clientNumber, scaleType) {
    const json = MakeRequest(
        `${URL}/invoices?scaleType=${scaleType}&clientNumber=${clientNumber}`
    );
    return json;
}

export const extractGradeAndSpeciesDataForScaleSite = (invoices) => {
    if (!invoices || Object.keys(invoices).length === 0) {
        return;
    }

    return invoices.reduce((acc, invoice) => {
        let scaleSite = invoice["header"]["scale-site"] || "Unknown";
        let x = AggregateSegregationDetailAndExtractParentChildData(
            [invoice],
            "grade-code",
            "species"
        );
    }, {});
};

export const processSegregationDetailAndExtractParentChildData = (
    invoiceArray,
    volumeText,
    parentKey,
    childKey
) => {
    return invoiceArray.reduce((acc, invoice) => {
        if (
            !Array.isArray(invoice["segregation-details"]["segregation-detail"])
        ) {
            invoice["segregation-details"]["segregation-detail"] = [
                invoice["segregation-details"]["segregation-detail"],
            ];
        }

        let recordAggregation = invoice["segregation-details"][
            "segregation-detail"
        ].reduce((recordAcc, record) => {
            const pCode = record[parentKey];
            const cCode = record[childKey];

            const additionalVolume = parseFloat(record[volumeText]);

            if (!recordAcc[pCode]) {
                recordAcc[pCode] = {};
            }
            if (!recordAcc[pCode][cCode]) {
                recordAcc[pCode][cCode] = 0;
            }
            recordAcc[pCode][cCode] += Math.round(additionalVolume);

            return recordAcc;
        }, {});

        Object.keys(recordAggregation).forEach((parentElem) => {
            if (!acc[parentElem]) {
                acc[parentElem] = {};
            }
            Object.keys(recordAggregation[parentElem]).forEach((childElem) => {
                if (!acc[parentElem][childElem]) {
                    acc[parentElem][childElem] = 0;
                }
                acc[parentElem][childElem] +=
                    recordAggregation[parentElem][childElem];
            });
        });
        return acc;
    }, {});
};

export const AggregateSegregationDetailAndExtractParentChildData = (
    invoices,
    parent,
    child
) => {
    const psiData = invoices.psi
        ? processSegregationDetailAndExtractParentChildData(
              invoices.psi,
              "net-volume",
              parent,
              child
          )
        : {};

    const wsiData = invoices.wsi
        ? processSegregationDetailAndExtractParentChildData(
              invoices.wsi,
              "additional-volume",
              parent,
              child
          )
        : {};

    let aggregatedData = { ...psiData };
    Object.keys(wsiData).forEach((p) => {
        if (!aggregatedData[p]) {
            aggregatedData[p] = {};
        }
        Object.keys(wsiData[p]).forEach((c) => {
            if (!aggregatedData[p][c]) {
                aggregatedData[p][c] = 0;
            }
            aggregatedData[p][c] += wsiData[p][c];
        });
    });

    return aggregatedData;
};
