import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
    TextField,
    Button,
    Typography,
    Container,
    Box,
    Card,
    CardHeader,
    useTheme,
    Avatar,
    CircularProgress,
    CardActionArea,
    Grid,
} from "@mui/material";
import { GetAllSaleInvoicesFromHBS, GetSaleStatusFromHBS } from "src/utils/hbs";
import {
    LogTimbermarkSearch,
    GetAllTimbermarkSearchesForUser,
    GetGeojsonCutblocks,
    GetLicensesFromBCDW,
} from "src/utils/api";
import LogitHelmet from "src/components/shared/LogitHelmet";
import slugify from "slugify";

function MarkSearch() {
    const [searchText, setSearchText] = useState("");
    const [previousSearches, setPreviousSearches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alternativeResults, setAlternativeResults] = useState([]); // State for alternative results
    const [message, setMessage] = useState(""); // State for user messages
    const navigate = useNavigate();
    const theme = useTheme();

    const examples = [
        { timbermark: "A2314" },
        { timbermark: "DZ5007" },
        { timbermark: "ER7570" },
    ];

    useEffect(() => {
        const fetchPreviousSearches = async () => {
            try {
                const storedSearches = await GetAllTimbermarkSearchesForUser();
                storedSearches.sort(
                    (a, b) => b.numberOfSearches - a.numberOfSearches
                );
                setPreviousSearches(storedSearches || []);
            } catch (error) {
                console.error("Error fetching previous searches:", error);
            }
        };

        fetchPreviousSearches();
    }, []);

    const handleSearch = async (timberMark) => {
        try {
            setLoading(true);
            setMessage(""); // Reset any previous messages
            setAlternativeResults([]); // Reset alternative results

            // Initial search
            let timberMarkStatus = await GetSaleStatusFromHBS(timberMark);
            console.log(timberMarkStatus);
            if (!timberMarkStatus) {
                // If no results, inform the user and try alternative searches
                setMessage(
                    `No timbermark results found for "${timberMark}". Searching for "${timberMark}" in licenses...`
                );

                // Example of alternative search logic
                // This could be a fuzzy search, wildcard search, or database query
                const licenseFeatures = await GetLicensesFromBCDW(timberMark);
                console.log(licenseFeatures);
                // find unique timbermarks in this list
                const uniqueTimberMarks = [
                    ...new Set(
                        licenseFeatures.features.map(
                            (feature) => feature.properties.TIMBER_MARK
                        )
                    ),
                ];

                // await searchForSimilarTimberMarks(timberMark);
                console.log(uniqueTimberMarks);
                if (uniqueTimberMarks.length > 0) {
                    setMessage("");
                    setAlternativeResults(uniqueTimberMarks);
                } else {
                    setMessage(
                        `No licenses or timbermarks found for "${timberMark}". Please try again.`
                    );
                }
            } else {
                // If results are found, proceed as usual
                let invoices = await GetAllSaleInvoicesFromHBS(
                    timberMark,
                    timberMarkStatus.appraisalInfo.effectivedetail
                );

                await LogTimbermarkSearch({ value: timberMark });
                const markSlug = slugify(timberMark);
                navigate("/app/harvest/" + markSlug, {
                    state: {
                        invoices: invoices,
                        timberMarkStatus: [timberMarkStatus],
                    },
                    replace: true,
                });
            }
        } catch (error) {
            console.error("Error handling search:", error);
            setMessage(
                "An error occurred during the search. Please try again."
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <LogitHelmet pageName={"Search"} />
            <Container maxWidth="md">
                <Box mt={4} mb={2}>
                    <Typography variant="h5" gutterBottom>
                        Search for a Timber Mark
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        We'll search the Harvest Billing System for this Timber
                        Mark and summarize the details for you.
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <TextField
                        label="Enter Timber Mark"
                        variant="outlined"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Box mt={2} position="relative">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSearch(searchText)}
                            disabled={loading}
                        >
                            Search
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: theme.palette.primary.main,
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Box>
                    {message && (
                        <Box mt={2}>
                            <Typography variant="body1" color="error">
                                {message}
                            </Typography>
                        </Box>
                    )}
                </Box>

                {/* Alternative Results */}
                {alternativeResults.length > 0 && (
                    <Box mt={4}>
                        <Typography variant="h6">
                            We found the following Timbermarks that are related
                            to your search:
                        </Typography>
                        <Grid container spacing={2}>
                            {alternativeResults.map((result, index) => (
                                <Grid item xs={12} sm={4} md={3} key={index}>
                                    <Card>
                                        <CardActionArea
                                            onClick={() => handleSearch(result)}
                                        >
                                            <CardHeader title={result} />
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}

                {/* Previously Searched Timber Marks */}
                {previousSearches.length > 0 && (
                    <Box mt={15}>
                        <Typography variant="h5">Search History</Typography>
                        <Grid container spacing={2}>
                            {previousSearches.map((search, index) => (
                                <Grid item xs={12} sm={4} md={3} key={index}>
                                    <Card>
                                        <CardActionArea
                                            onClick={() =>
                                                handleSearch(search.timbermark)
                                            }
                                        >
                                            <CardHeader
                                                title={search.timbermark}
                                            />
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}

                {/* Examples */}
                <Box mt={15}>
                    <Typography variant="h5">Examples</Typography>
                    <Typography variant="body1">
                        Not sure where to start? Check out these timber marks.
                    </Typography>
                    <Grid container spacing={2}>
                        {examples.map((search, index) => (
                            <Grid item xs={12} sm={4} md={3} key={index}>
                                <Card>
                                    <CardActionArea
                                        onClick={() =>
                                            handleSearch(search.timbermark)
                                        }
                                    >
                                        <CardHeader title={search.timbermark} />
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default MarkSearch;
