import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        "@global": {
            "*": {
                boxSizing: "border-box",
                margin: 0,
                padding: 0,
            },
            html: {
                "-webkit-font-smoothing": "antialiased",
                "-moz-osx-font-smoothing": "grayscale",
                height: "100%",
                width: "100%",
            },
            body: {
                backgroundColor: "#f4f6f8",
                height: "100%",
                width: "100%",
            },
            a: {
                textDecoration: "none",
            },
            "#root": {
                height: "100%",
                width: "100%",
            },
            ".video-responsive": {
                overflow: "hidden",
                paddingBottom: "56.25%",
                position: "relative",
                height: "0",
            },
            iframe: {
                left: 0,
                top: 0,
                height: "100%",
                width: "100%",
                position: "absolute",
            },
        },
    })
);

const GlobalStyles = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
