import { getAuth } from "firebase/auth";
import {
    collection,
    doc,
    setDoc,
    getFirestore,
    addDoc,
    getDocs,
    deleteDoc,
    query,
    where,
    onSnapshot,
    updateDoc,
    getDoc,
} from "firebase/firestore";

import {} from "firebase/firestore";
import slugify from "slugify";
import { GetAllSaleInvoicesFromHBS } from "./hbs";
import { USER_COLLECTION } from "src/Constants";
import { BCDATA_API_ENDPOINT, BCDATA_API_ENDPOINT_V2 } from "src/Config";
import { center } from "@turf/turf";

export async function LogTimbermarkSearch({ value }) {
    const db = getFirestore();
    const userId = getAuth().currentUser.uid;

    let searchResults = await GetTimbermarkSearchForUser(value);

    if (searchResults) {
        searchResults.numberOfSearches += 1;
        searchResults.lastSearch = new Date();
        return await setDoc(
            doc(
                db,
                `fibreflow/${userId}/timbermarkSearches/${searchResults.id}`
            ),
            searchResults
        );
    }

    // If no previous search found, create a new document
    return await addDoc(
        collection(db, `fibreflow/${userId}/timbermarkSearches`),
        {
            timbermark: value,
            numberOfSearches: 1,
            lastSearch: new Date(),
        }
    );
}

export async function GetTimbermarkSearchForUser(timbermark) {
    const db = getFirestore();
    const userId = getAuth().currentUser.uid;

    const q = query(
        collection(db, `fibreflow/${userId}/timbermarkSearches`),
        where("timbermark", "==", timbermark)
    );

    const querySnapshot = await getDocs(q);

    // Check if any document matches
    if (!querySnapshot.empty) {
        // Assuming there should only be one match, return the first one
        const doc = querySnapshot.docs[0];

        return { id: doc.id, ...doc.data() };
    }

    // Return null if no matches are found
    return null;
}

export async function GetAllTimbermarkSearchesForUser() {
    // Add your code here
    const db = getFirestore();
    const userId = getAuth().currentUser.uid;

    // Assuming `db` is your Firestore instance and `userId` is the user's ID.
    return await getDocs(
        collection(db, `fibreflow/${userId}/timbermarkSearches`)
    ).then((querySnapshot) => {
        const searches = querySnapshot.docs.map((doc) => doc.data());
        return searches;
    });
}

export async function CreateTimbermarkGroup({ name, timbermarks }) {
    // Add your code here
    const db = getFirestore();

    // write to the firebase collection  "usersTimbermarkGroups/{userId}/groups/{groupName}"
    // with the timbermarks array
    // return the groupName
    // get the current user id
    const userId = getAuth().currentUser.uid;

    // Assuming `db` is your Firestore instance, `userId` is the user's ID, `groupName` is the name of the group,
    // and `timbermarks` is the array of timbermarks.

    // await addDoc(docRef, data);
    const slug = slugify(name, {
        lower: true, // Convert to lowercase
        strict: true, // Remove special characters
        remove: /[*+~.()'"!:@]/g, // Customize character removal
    });
    const uniqueHarvestRoute = `${slug}-${userId}`;

    await addDoc(collection(db, `fibreflow/${userId}/timbermarkGroups`), {
        name: name,
        slug: uniqueHarvestRoute,
        timbermarks: timbermarks,
    });

    return name;
}

export async function DeleteTimbermarkGroup(id) {
    // Add your code here
    const db = getFirestore();
    const userId = getAuth().currentUser.uid;

    // Assuming `db` is your Firestore instance, `userId` is the user's ID, and `groupName` is the name of the group.

    // delete the group from the collection
    await deleteDoc(doc(db, `fibreflow/${userId}/timbermarkGroups/${id}`));

    return id;
}

export async function GetGroupsForUser() {
    const db = getFirestore();
    const userId = getAuth().currentUser.uid;
    const groups = await getDocs(
        collection(db, `fibreflow/${userId}/timbermarkGroups`)
    );
    const groupsData = groups.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

    return groupsData;
}

export async function GetInvoicesForTimbermarks(timbermarks) {
    // for all timbermarks in the group
    // get the invoices
    const invoices = {
        psi: [],
        wsi: [],
    };

    for (const timbermark of timbermarks) {
        const timbermarkInvoices = await GetAllSaleInvoicesFromHBS(timbermark);
        if (!timbermarkInvoices) {
            continue;
        }
        if (timbermarkInvoices.psi) {
            invoices.psi.push(...timbermarkInvoices.psi);
        }
        if (timbermarkInvoices.wsi) {
            invoices.wsi.push(...timbermarkInvoices.wsi);
        }
    }

    return invoices;
}

export async function GetClientNameFromBCTS(licenceId) {
    // read from bcts-sales collection where ffid = licence id

    const db = getFirestore();
    const q = query(
        collection(db, "bcts-sales"),
        where("ffid", "==", licenceId)
    );

    const querySnapshot = await getDocs(q);

    // Check if any document matches
    if (!querySnapshot.empty) {
        // Assuming there should only be one match, return the first one
        const doc = querySnapshot.docs[0];

        return doc.data().winning_client_name;
    }
    return null;
}

/* USER FUNCTIONS
 */

export async function UpdateUser(user) {
    try {
        const db = getFirestore();

        let docRef = doc(db, USER_COLLECTION, getAuth().currentUser.uid);
        let newUser = {
            id: user.id,
            name: user.name,
            email: user.email,
            organization: user.organization ? user.organization : "",
            phone_number: user.phone_number ? user.phone_number : "",
            updated_at: new Date(),
        };

        return await updateDoc(docRef, newUser);
    } catch (err) {
        return null;
    }
}

export async function GetUser() {
    const db = getFirestore();
    const userRef = doc(db, USER_COLLECTION, getAuth().currentUser.uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
        return userDoc.data();
    }

    return null;
}

/* GEOJSON FUNCTIONS
 */

export async function GetLicensesFromBCDW(searchCriteria) {
    let query = CreateGeojsonCutblockQueryForLicenses([searchCriteria]);
    query += `&drop_geometry=true`;
    console.log(query);
    const result = await GetGeojsonCutblocks(query);
    console.log(result);
    return result;
}

export async function GetCuttingPermitsFromBCDW(searchCriteria) {
    let query = CreateGeojsonCutblockQueryForLicenses([searchCriteria]);
    query += `&drop_geometry=true`;
    console.log(query);
    const result = await GetGeojsonCutblocks(query);
    console.log(result);
    return result;
}

export async function GetGeojsonCutblocks(query) {
    if (query === "") return;
    let url = `${BCDATA_API_ENDPOINT_V2}/bcdw/fta?query=${query}`;
    console.log(url);
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
        },
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.error("Error fetching cutblocks", error);
            return null;
        });

    if (response !== null) {
        return response;
    }

    return null;
}

export function GetGeojsonCenter(geojson) {
    return center(geojson);
}

export function CreateGeojsonCutblockQueryForTimbermarks(ids) {
    let query = "";
    ids.forEach((id) => {
        if (id.includes(" ")) {
            id?.split(" ").forEach((subId) => {
                query += `TIMBER_MARK=%27${subId}%27 OR `;
            });
        }
        query += `TIMBER_MARK=%27${id}%27 OR `;
    });
    // remove the last OR
    return query.substring(0, query.length - 4);
}

export function CreateGeojsonCutblockQueryForLicenses(ids) {
    let query = "";
    console.log(ids);
    ids.forEach((id) => {
        console.log("inside", id);
        if (id.includes(" ")) {
            console.log(id);
            id?.split(" ").forEach((subId) => {
                query += `CUT_BLOCK_FOREST_FILE_ID=%27${subId}%27 OR `;
            });
        }
        query += `CUT_BLOCK_FOREST_FILE_ID=%27${id}%27 OR `;
    });
    console.log(query);
    // remove the last OR
    return query.substring(0, query.length - 4);
}

export function CreateGeojsonCutblockQueryForCuttingPermits(ids) {
    let query = "";
    console.log(ids);
    ids.forEach((id) => {
        console.log("inside", id);
        if (id.includes(" ")) {
            console.log(id);
            id?.split(" ").forEach((subId) => {
                query += `CUT_BLOCK_FOREST_FILE_ID=%27${subId}%27 OR `;
            });
        }
        query += `CUT_BLOCK_FOREST_FILE_ID=%27${id}%27 OR `;
    });
    console.log(query);
    // remove the last OR
    return query.substring(0, query.length - 4);
}
/* PAYMENT FUNCTIONS
 */

export async function CreateSubscriptionCheckoutSession(
    priceId,
    success_url_extension
) {
    const db = getFirestore();
    let docRef = await addDoc(
        collection(
            db,
            "customers",
            getAuth().currentUser.uid,
            "checkout_sessions"
        ),
        {
            automatic_tax: true, // Automatically calculate tax based on the customer's address
            tax_id_collection: true, // Collect the customer's tax ID
            trial_from_plan: true,
            allow_promotion_codes: true,
            price: priceId,
            success_url: `${window.location.origin}${success_url_extension}`,
            cancel_url: window.location.origin,
        }
    );
    const unsubscribe = onSnapshot(docRef, (doc) => {
        const { error, url } = doc.data();
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.

            window.location.assign(url);
        }
    });
}
