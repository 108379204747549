import { getDownloadURL, getStorage, ref } from "firebase/storage";

async function GetDownloadUrl(sale_url) {
    if (!sale_url) {
        return null;
    }
    try {
        var storage = getStorage();

        var blobRef = ref(storage, sale_url);
        return getDownloadURL(blobRef);
    } catch (error) {}
}

export { GetDownloadUrl };
