import { createStore } from "redux"
import rootReducer from "src/redux/Reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
//persist config
const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)

//Create Redux store
const store = createStore(persistedReducer);
const persistor = persistStore(store);


export { store, persistor};