import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyDdcST8ikViSGIwhQhqTfiCLzJ5YUqUuWY",
    authDomain: "logitnow.firebaseapp.com",
    projectId: "logitnow",
    storageBucket: "logitnow.appspot.com",
    messagingSenderId: "670275449827",
    appId: "1:670275449827:web:94dd250c182b3b886066d6",
    measurementId: "G-6J523B0JWC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app, "us-west2");

export { app, analytics, auth, db, functions };
