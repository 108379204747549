const Thumbnail = (props) => (
  <img
    alt='Thumbnail'
    height='52px'
    width='36px'
    src='/static/images/assets/LogitTree_White.png'
    {...props}
  />
);

export default Thumbnail;
