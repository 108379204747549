import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

export default createMuiTheme({
    palette: {
        background: {
            default: "#F4F6F8",
            paper: colors.common.white,
        },
        paper: {
            default: "#fff",
        },
        primary: {
            contrastText: "#ffffff",
            main: "#006666",
            light: "#008c8c",
        },
        secondary: {
            contrastText: "#ffffff",
            main: "#053d3b",
        },
        accent: {
            contrastText: "#ffffff",
            main: "#00A8E8",
        },
        white: {
            contrastText: "#ffffff",
            main: "#fff",
        },
        new: {
            contrastText: "#ffffff",
            main: "#119822",
        },
        closing: {
            contrastText: "#ffffff",
            main: "#B3001B",
        },
        gray: {
            contrastText: "#ffffff",
            main: "#808080",
        },

        text: {
            primary: "#172b4d",
            secondary: "#6b778c",
            new: "#228B22",
            closing: "#FF0000",
        },
    },
    shadows,
    typography,
});

// export default theme;
