import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";

import { Expand as ExpandMoreIcon } from "@material-ui/icons";
import {
    cleanText,
    formatCurrency,
    formatNumber,
    formatPercentage,
} from "src/utils/helpers";
import { M3 } from "src/Constants";
import { Grid } from "@mui/material";
import { Tooltip } from "@material-ui/core";
import { calculateTotalPerM3Stumpage } from "./helpers";

// Utility to render toggle button groups with scrolling if necessary
const ToggleGroup = ({
    title,
    options,
    selectedValues,
    handleChange,
    clearAll,
    selectAll,
}) => (
    <Grid
        container
        sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "left",
            mb: 2,
        }}
    >
        <Grid item xs={12}>
            <Typography variant="subtitle2">{title}:</Typography>
        </Grid>
        <Grid
            item
            xs={12}
            sx={{
                maxHeight: "150px", // Limit the height for scrollable area
                overflowY: "auto", // Enable vertical scrolling
                mb: 2,
            }}
        >
            <ToggleButtonGroup
                value={selectedValues}
                onChange={handleChange}
                aria-label={`${title.toLowerCase()} toggle`}
                sx={{ marginRight: 2 }}
            >
                {options.map((option) => (
                    <ToggleButton key={option} value={option}>
                        {cleanText(option)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Grid>
        <Grid item xs={1}>
            <Button onClick={clearAll} sx={{ marginRight: 1 }}>
                Clear All
            </Button>
        </Grid>
        <Grid item xs={1}>
            <Button onClick={selectAll}>Select All</Button>
        </Grid>
    </Grid>
);

const TotalHarvestSummary = ({ timberMarkStatus, invoices }) => {
    const [aggregatedSummary, setAggregatedSummary] = useState({
        totalVolume: 0,
        totalValue: 0,
        totalWeight: 0,
        totalLoads: 0,
        totalPieceCount: 0,
        totalStumpage: 0,
        totalConversion: 0,
    });
    const [uniqueTimberMarks, setUniqueTimberMarks] = useState([]);
    const [selectedTimberMarks, setSelectedTimberMarks] = useState([]);
    const [uniqueDestinations, setUniqueDestinations] = useState([]);
    const [selectedDestinations, setSelectedDestinations] = useState([]);
    const [selectedScales, setSelectedScales] = useState(["weight", "piece"]);

    // Organize invoices by timber mark and extract unique timber marks and destinations
    useEffect(() => {
        if (!invoices || invoices.length === 0) return;

        let timberMarksSet = new Set();
        let destinationsSet = new Set();

        invoices?.wsi?.forEach((invoice) => {
            const timberMark = invoice.header["timber-mark"];
            const destination = invoice.header["scale-site"]["name"];
            timberMarksSet.add(timberMark);
            destinationsSet.add(destination);
        });

        invoices?.psi?.forEach((invoice) => {
            const timberMark = invoice.header["timber-mark"];
            const destination =
                invoice.header["kind-of-charge"] === "Waste"
                    ? "Waste"
                    : invoice.header["scale-site"]["name"];
            timberMarksSet.add(timberMark);
            destinationsSet.add(destination);
        });

        setUniqueTimberMarks([...timberMarksSet]);
        setSelectedTimberMarks([...timberMarksSet]);
        setUniqueDestinations([...destinationsSet]);

        setSelectedDestinations(
            [...destinationsSet].filter(
                (destination) => destination !== "Waste"
            )
        );
    }, [invoices]);

    // Aggregation logic
    useEffect(() => {
        if (selectedDestinations.length === 0) {
            setAggregatedSummary({
                totalVolume: 0,
                totalValue: 0,
                totalWeight: 0,
                totalLoads: 0,
                totalPieceCount: 0,
                totalStumpage: 0,
                totalConversion: 0,
            });
            return;
        }

        let totalVolume = 0,
            totalValue = 0,
            totalWeight = 0,
            totalLoads = 0,
            totalPieceCount = 0,
            totalStumpage = 0;
        let activeTimbermarkStatusObjects = [];
        selectedTimberMarks.forEach((timberMark) => {
            // find just this timberMark in timberMarkStatus
            const thisStatus = timberMarkStatus.find(
                (status) => status?.timberMarkInfo.timberMark === timberMark
            );
            activeTimbermarkStatusObjects.push(thisStatus);

            if (selectedScales.includes("weight")) {
                invoices?.wsi?.forEach((invoice) => {
                    if (
                        invoice.header["timber-mark"] === timberMark &&
                        selectedDestinations.includes(
                            invoice.header["scale-site"]["name"]
                        )
                    ) {
                        const volume = parseFloat(
                            invoice.header["total-volume"]
                        );
                        const value = parseFloat(
                            invoice.header["invoice-amount"]
                        );
                        const weight = parseFloat(
                            invoice.header["total-weight"]
                        );
                        const loads = parseFloat(invoice.header["total-loads"]);

                        totalVolume += volume;
                        totalValue += value;
                        totalWeight += weight;
                        totalLoads += loads;
                        totalStumpage += value / volume;
                    }
                });
            }

            if (selectedScales.includes("piece")) {
                invoices?.psi?.forEach((invoice) => {
                    const isWaste =
                        invoice.header["kind-of-charge"] === "Waste";
                    const destination = isWaste
                        ? "Waste"
                        : invoice.header["scale-site"]?.["name"];

                    if (
                        invoice.header["timber-mark"] === timberMark &&
                        ((isWaste && selectedDestinations.includes("Waste")) ||
                            selectedDestinations.includes(destination))
                    ) {
                        const volume = parseFloat(
                            invoice.header["total-volume"]
                        );
                        const value = parseFloat(
                            invoice.header["invoice-amount"]
                        );
                        const pieces = parseFloat(
                            invoice["segregation-details"][
                                "segregation-totals"
                            ]["pieces"]
                        );
                        const loads = invoice["detail-documents"]
                            ? Array.isArray(
                                  invoice["detail-documents"]["detail-document"]
                              )
                                ? invoice["detail-documents"]["detail-document"]
                                      .length
                                : 1
                            : 0;

                        totalVolume += volume;
                        totalLoads += loads;
                        totalValue += value;
                        totalPieceCount += pieces;
                    }
                });
            }
        });

        const totalConversion = totalWeight / totalVolume;
        const aggregatedPerMStumpage = totalValue / totalVolume;
        const avgUpsetRate = calculateTotalPerM3Stumpage(
            activeTimbermarkStatusObjects
        );

        setAggregatedSummary({
            totalVolume,
            totalValue,
            totalWeight,
            totalLoads,
            totalPieceCount,
            totalStumpage: aggregatedPerMStumpage,
            totalUpset: avgUpsetRate,
            totalConversion,
            offGrade: 1 - aggregatedPerMStumpage / avgUpsetRate,
        });
    }, [
        selectedTimberMarks,
        selectedDestinations,
        invoices,
        selectedScales,
        timberMarkStatus,
    ]);

    // Handlers for clearing and selecting all
    const clearScales = () => setSelectedScales([]);
    const selectAllScales = () => setSelectedScales(["weight", "piece"]);

    const clearTimberMarks = () => setSelectedTimberMarks([]);
    const selectAllTimberMarks = () =>
        setSelectedTimberMarks([...uniqueTimberMarks]);

    const clearDestinations = () => setSelectedDestinations([]);
    const selectAllDestinations = () =>
        setSelectedDestinations([...uniqueDestinations]);

    const handleTimberMarkToggle = (event, newValues) =>
        setSelectedTimberMarks(newValues);
    const handleDestinationToggle = (event, newValues) =>
        setSelectedDestinations(newValues);
    const handleScaleTypeToggle = (event, newScales) =>
        setSelectedScales(newScales);

    return (
        <div>
            <Card>
                <CardContent>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Summary Explorer
                        </Typography>

                        {/* Filter Section - Accordion for mobile */}

                        <Accordion>
                            <Tooltip title="Click to filter by scale type, timber mark, and destination">
                                <AccordionSummary
                                    // put the icon on the left

                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Filters</Typography>
                                </AccordionSummary>
                            </Tooltip>
                            <AccordionDetails>
                                <ToggleGroup
                                    title="Scale Type"
                                    options={["weight", "piece"]}
                                    selectedValues={selectedScales}
                                    handleChange={handleScaleTypeToggle}
                                    clearAll={clearScales}
                                    selectAll={selectAllScales}
                                />
                                <ToggleGroup
                                    title="Timber Marks"
                                    options={uniqueTimberMarks}
                                    selectedValues={selectedTimberMarks}
                                    handleChange={handleTimberMarkToggle}
                                    clearAll={clearTimberMarks}
                                    selectAll={selectAllTimberMarks}
                                />
                                <ToggleGroup
                                    title="Destinations"
                                    options={uniqueDestinations}
                                    selectedValues={selectedDestinations}
                                    handleChange={handleDestinationToggle}
                                    clearAll={clearDestinations}
                                    selectAll={selectAllDestinations}
                                />
                            </AccordionDetails>
                        </Accordion>

                        {/* Aggregated Table */}
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total Volume (m³)</TableCell>
                                        <TableCell>Total Loads</TableCell>
                                        {selectedScales.includes("weight") && (
                                            <TableCell>
                                                Total Weight (kg)
                                            </TableCell>
                                        )}
                                        {selectedScales.includes("piece") && (
                                            <TableCell>Total Pieces</TableCell>
                                        )}
                                        <TableCell>Total Value</TableCell>
                                        <TableCell>Upset $/m³</TableCell>
                                        <TableCell>Actual $/m³</TableCell>
                                        <TableCell>Off-Grade</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {formatNumber(
                                                aggregatedSummary.totalVolume
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {formatNumber(
                                                aggregatedSummary.totalLoads
                                            )}
                                        </TableCell>
                                        {selectedScales.includes("weight") && (
                                            <TableCell>
                                                {formatNumber(
                                                    aggregatedSummary.totalWeight
                                                )}
                                            </TableCell>
                                        )}
                                        {selectedScales.includes("piece") && (
                                            <TableCell>
                                                {formatNumber(
                                                    aggregatedSummary.totalPieceCount
                                                )}
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            {formatCurrency(
                                                aggregatedSummary.totalValue
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {formatCurrency(
                                                aggregatedSummary.totalUpset
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {formatCurrency(
                                                aggregatedSummary.totalStumpage
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {formatPercentage(
                                                aggregatedSummary.offGrade
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default TotalHarvestSummary;
